import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import IframeResizer from "iframe-resizer-react"

import * as styles from "../css/inquire.module.css"
import IgWidget from "../components/igWidget";

const InquirePage = (props) => {
  return(
  <Layout>
    <Seo title="Perfectly Yours Travel Co. Contact Page" />
    <div className={styles.fullContainer}>
      <div className={styles.layoutContainer}>
        <div className={styles.container}>
          <h1 className={styles.logoText}>Travel Inquiry</h1>
          <h4>please fill out this inquiry form to start the planning process:</h4>
        </div>
        <IframeResizer
            heightCalculationMethod="lowestElement"
            inPageLinks
            log
            className={styles.iframe}
            src="https://traveljoy.com/webforms/fPr3McRUJLpdEQH4UAieH68x/forms/rJvJd3bFfSkSSEy2CeL4UcMS"
          />
      </div>
      <IgWidget />
    </div>
  </Layout>
)}

export default InquirePage
